<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <data-grid
            :envSelected="envSelected"
            :grid="grid"
            @doAction="gridAction"
        >

          <template v-slot:column-name="{ item }">
            <div class="font-weight-medium">{{ item.name }}</div>
          </template>

          <template v-slot:column-category="{ item }">
            <div class="text-capitalize">{{ item.type }} / {{ item.category }}</div>
          </template>

          <template v-slot:column-expanded="{ headers, item }">
            <td :colspan="headers.length">
              <json-viewer
                  class="my-4"
                  :value="item.config"
                  :expand-depth="4"
                  theme="my-awesome-json-theme"
                  copyable
                  boxed
                  sort
              ></json-viewer>
            </td>

          </template>
        </data-grid>

      </v-col>
    </v-row>

    <clone-dialog
        :envSelected="envSelected"
        :options="cloneDialog"
        :doAction="doClone"
    ></clone-dialog>

    <share-dialog
        :envSelected="envSelected"
        :options="shareDialog"
        :doAction="doShare"
    >
    </share-dialog>

  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import CloneDialog from "@/views/internal/components/custom/cloneDialog"
import ShareDialog from "@/views/internal/components/custom/shareDialog"
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'DataClusters',

  mixins: [globalMixins],

  components: {
    dataGrid,
    CloneDialog,
    ShareDialog,
    JsonViewer
  },

  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  },

  data: () => {
    return {
      grid: {
        //mandatory
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-expand'
          // },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Category',
            value: 'category',
          },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        columns: [
          {
            name: 'item.name'
          },
          {
            name: 'item.category'
          },
          {
            name: 'item.expanded'
          }
        ],
        //optional
        search: {
          keyword: ''
        },
        //optional
        itemID: 'name',
        //optional
        expand: {
          single: false,
          items: []
        },
        //optional
        multi: [
          {
            icon: 'delete',
            label: 'Delete',
            color: 'error',
            acl: {method: 'delete', route: `/environments/:env/data-clusters/:id`},
            method: 'deleteItem',
            //optional
            confirm: `Are you sure you want to delete these clusters(s)?`
          }
        ],
        //optional
        sortingList: ['name', 'category'],
        //optional
        headActions: [
          {
            icon: 'refresh',
            label: '',
            color: 'purple',
            acl: {method: 'get', route: `/environments/:env/data-clusters`},
            method: 'listItems'
          },
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            acl: {method: 'put', route: `/environments/:env/data-clusters`},
            method: 'addItem'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'lockUnlockItem',
            color: 'error',
            icon: 'mdi-lock',
            alternate: {
              key: 'locked',
              color: 'success',
              icon: 'mdi-lock-open-variant'
            },
            allow: {
              method: 'patch',
              route: '/environments/:env/data-clusters/:id'
            },
            tooltip: 'Lock / Unlock Cluster'
          },
          {
            method: 'cloneItem',
            color: 'secondary',
            icon: 'mdi-content-copy',
            allow: {
              method: 'post',
              route: '/environments/:env/data-clusters/:id/clone'
            },
            tooltip: 'Clone'
          },
          {
            method: 'shareItem',
            color: 'primary',
            icon: 'mdi-share-variant',
            allow: {
              method: 'post',
              route: '/environments/:env/data-clusters/:id/share'
            },
            tooltip: 'Share'
          },
          {
            method: 'editItem',
            color: 'success',
            icon: 'mdi-pencil',
            allow: {
              method: 'patch',
              route: '/environments/:env/data-clusters/:id'
            },
            tooltip: 'Edit'
          },
          {
            method: 'deleteItem',
            color: 'error',
            icon: 'mdi-delete',
            allow: {
              method: 'delete',
              route: '/environments/:env/data-clusters/:id'
            },
            tooltip: 'Delete'
            // optional
            // confirm: `Are you sure you want to delete this Custom Setting?`
          },
        ]
      },
      cloneDialog: {
        trigger: false,
        original: {},
        actions: [
          {
            color: 'success',
            icon: 'content-copy',
            label: 'Clone',
            method: 'doClone',
            allow: {
              method: 'post',
              route: '/environments/:env/data-clusters/:id/clone'
            }
          }
        ]
      },
      shareDialog: {
        trigger: false,
        original: {},
        list: [],
        actions: [
          {
            color: 'success',
            icon: 'share-variant',
            label: 'Share',
            method: 'doShare',
            allow: {
              method: 'post',
              route: '/environments/:env/data-clusters/:id/share'
            }
          }
        ]
      }
    };
  },

  methods: {

    //list
    async listItems() {
      this.grid.loading = true;
      this.grid.items = [];
      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/data-clusters`,
        method: "get",
      };

      this.getSendData(apiOptions).then((response) => {
        this.grid.items = response.items || [];
        this.grid.loading = false;
      });
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    //add
    addItem() {
      this.goToPage({route: 'createResource'});
    },

    //edit
    editItem(item) {
      this.goToPage({route: 'editResource', params: {id: item.id}});
    },

    //lock | unlock
    lockUnlockItem: function (item) {
      const self = this;
      let op = !item.locked ? 'LOCKED' : 'Unlocked';
      item.locked = !item.locked;

      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/data-clusters/${item.id}`,
        method: "patch",
        params: {
          locked: item.locked
        }
      };

      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item ${op}`,
          text: `This cluster has been ${op}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //delete
    deleteItem(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to remove cluster: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/environments/${_self.envSelected.value}/data-clusters/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions).then((response) => {
          _self.pushMessage({
            type: 'success',
            title: `Cluster Deleted`,
            text: `This Cluster entry has been deleted.`
          });
          setTimeout(() => {
            _self.listItems();
          }, 2000);
        });
      }
    },

    //clone
    cloneItem(item) {
      this.cloneDialog.original = {...item};
      this.cloneDialog.trigger = true;
    },

    doClone(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/data-clusters/${item.id}/clone`,
        method: "post",
        params: {name: value}
      };
      this.cloneDialog.trigger = false;
      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item Copied`,
          text: `Cluster has been copied to ${value}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //share
    shareItem(item) {
      this.shareDialog.original = {...item};
      this.shareDialog.trigger = true;
      this.shareDialog.list = [...this.environments];
      for (let i = this.shareDialog.list.length - 1; i >= 0; i--) {
        if (item.env[this.shareDialog.list[i].code.toUpperCase()]) {
          this.shareDialog.list.splice(i, 1);
        }
      }
    },

    doShare(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/data-clusters/${item.id}/share`,
        method: "post",
        params: {environments: value}
      };
      this.shareDialog.trigger = false;
      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item Shared`,
          text: `Cluster entry has been shared with environments: ${value.join(' - ')}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 4000);
      });
    }
  },

  async created() {
    setTimeout( () => {
      this.listItems();
    }, 1000 );
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
