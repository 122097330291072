<template>
  <v-container
      id="resources"
      fluid
      align="center"
      tag="section"
  >

    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-server"
            inline
            title="Resources"
        >
          <v-tabs right>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env/data-clusters'})">
              <v-icon class="mr-2">mdi-server</v-icon>
              Clusters
              <help-circle :context="{bottom: true, link: 'clusters', text: 'Connected Database Clusters'}"></help-circle>
            </v-tab>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env/databases'})">
              <v-icon class="mr-2">mdi-database</v-icon>
              Databases
            </v-tab>

            <v-tab-item><data-clusters :environments="environments" :env-selected="envSelected"></data-clusters></v-tab-item>
            <v-tab-item><databases :environments="environments" :env-selected="envSelected"></databases></v-tab-item>
          </v-tabs>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import DataClusters from '@/views/internal/pages/resources/Clusters';
import Databases    from "@/views/internal/pages/resources/Databases";
import globalMixins from "@/mixins/globalMixins";
import HelpCircle   from "@/views/internal/components/custom/helpCircle";

export default {
  name: 'resources',

  mixins: [globalMixins],

  components: {
    HelpCircle,
    DataClusters,
    Databases
  },
  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  }
};
</script>