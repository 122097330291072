<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <data-grid
            :envSelected="envSelected"
            :grid="grid"
            @doAction="gridAction"
        >

          <template v-slot:column-name="{ item }">
            <div class="font-weight-medium">{{ item.name }}</div>
          </template>

          <template v-slot:column-cluster="{ item }">
            <v-chip label color="orange" class="text-capitalize">{{ item.cluster }}</v-chip>
          </template>

          <template v-slot:column-multitenant="{ item }">
            <v-chip label :color="item.multitenant? 'error': 'secondary'" class="text-capitalize">{{ item.multitenant? 'Yes': 'No' }}</v-chip>
          </template>

        </data-grid>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import dataGrid     from "@/views/internal/components/custom/dataGrid";

export default {
  name: 'Databases',

  mixins: [ globalMixins ],

  components :{
    dataGrid
  },

  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  },

  data: () => {
    return {
      grid: {
        //mandatory
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-expand'
          // },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Cluster',
            value: 'cluster',
          },
          {
            text: 'Multi-Tenant',
            value: 'multitenant',
          },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        columns: [
          {
            name: 'item.name'
          },
          {
            name: 'item.cluster'
          },
          {
            name: 'item.multitenant'
          },
          {
            name: 'item.expanded'
          }
        ],
        //optional
        search: {
          keyword: ''
        },
        //optional
        itemID: 'name',
        //optional
        // expand: {
        //   single: false,
        //   items: []
        // },
        //optional
        multi: [
          {
            icon: 'delete',
            label: 'Delete',
            color: 'error',
            acl: { method: 'delete', route: `/environments/:env/databases/:name` },
            method: 'deleteItem',
            //optional
            confirm: `Are you sure you want to delete these clusters(s)?`
          }
        ],
        //optional
        sortingList: [ 'name', 'category' ],
        //optional
        headActions: [
          {
            icon: 'refresh',
            label: '',
            color: 'purple',
            acl: { method: 'get', route: `/environments/:env/databases` },
            method: 'listItems'
          },
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            acl: { method: 'put', route: `/environments/:env/databases` },
            method: 'addItem'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'lockUnlockItem',
            color: 'error',
            icon: 'mdi-lock',
            alternate: {
              key: 'locked',
              color: 'success',
              icon: 'mdi-lock-open-variant'
            },
            allow: {
              method: 'patch',
              route: '/environments/:env/databases/:name'
            },
            tooltip:'Lock / Unlock Database'
          },
          {
            method: 'editItem',
            color: 'success',
            icon: 'mdi-pencil',
            allow: {
              method: 'patch',
              route: '/environments/:env/databases/:name'
            },
            tooltip:'Edit'
          },
          {
            method: 'deleteItem',
            color: 'error',
            icon: 'mdi-delete',
            allow: {
              method: 'delete',
              route: '/environments/:env/databases/:name'
            },
            tooltip:'Delete'
            // optional
            // confirm: `Are you sure you want to delete this Custom Setting?`
          },
        ]
      }
    };
  },

  methods: {

    //list
    async listItems() {
      this.grid.loading = true;
      this.grid.items = [];
      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${ this.envSelected.value }/databases`,
        method: "get",
      };

      this.getSendData( apiOptions ).then( ( response ) => {
        this.grid.items = response.items || [];
        this.grid.loading = false;
      } );
    },

    //grid action hook
    gridAction( action, item, extra ) {
      if ( item ) {
        this[ action ]( item, extra );
      }
      else {
        this[ action ]( extra );
      }
    },

    //add
    addItem() {
      this.goToPage( { route: 'createDatabase', params: {envCode: this.envSelected.value } } );
    },

    //edit
    editItem( item ) {
      this.goToPage( { route: 'editDatabase', params: { id: item.name } } );
    },

    //lock | unlock
    lockUnlockItem: function ( item ) {
      const self = this;
      let op = !item.locked ? 'LOCKED' : 'Unlocked';
      item.locked = !item.locked;

      const apiOptions = {
        url: `/consoleapi/environments/${ self.envSelected.value }/databases/${ item.name }`,
        method: "patch",
        params: item
      };
      self.getSendData( apiOptions ).then( ( response ) => {
        self.pushMessage( {
                            type: 'success',
                            title: `Item ${ op }`,
                            text: `This database has been ${ op }.`
                          } );
        setTimeout( () => {
          self.listItems();
        }, 4000 );
      } );
    },

    //delete
    deleteItem( item, multi ) {
      const _self = this;
      if ( multi || ( !multi && confirm( `Are you sure you want to remove database: ${ item.name }` ) ) ) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/environments/${ _self.envSelected.value }/databases/${ item.name }`,
          method: "delete"
        };
        _self.getSendData( apiOptions ).then( ( response ) => {
          _self.pushMessage( {
                               type: 'success',
                               title: `Item Deleted`,
                               text: `This database entry has been deleted.`
                             } );
          setTimeout( () => {
            _self.listItems();
          }, 2000 );
        } );
      }
    }

  },

  async created() {
    setTimeout( () => {
      this.listItems();
    }, 1000 );

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
